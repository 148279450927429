// Loader.js
import React from 'react';
import '../assets/css/Loader.css'; // Import the CSS for styling

const Loader = () => (
  <div className="loader-wrapper">
    <div className="spinner"></div>
  </div>
);

export default Loader;